<template>
  <div class="iriets-home">
    <div class="top-banner">
      <img class="about-image" src="@/assets/about/1.png" alt="">
      <p class="b-title">关于我们</p>
      <p class="b-desc">南京易锐思科技有限公司是位于南京市雨花台区软件谷的国家高新技术企业，是一家专注于软件定制化开发和提供成套IT解决方案的优质企业。</p>
    </div>
    <p class="title">团队能力</p>
    <div class="team-box wrapper">
      <el-row :gutter="80">
        <el-col :span="8">
          <img src="@/assets/about/2.png" alt="">
          <div>
            <span class="s-title f16 mtb-10">技术精湛</span>
            <span class="s-desc f14 c666">专业：高级专家，工程师梯队构建合理。</span>
            <span class="s-desc f14 c666">有效：持续交付，对参与项目的有效整合。</span>
            <span class="s-desc f14 c666">创新：技术敏感，可持续创新的激励机制。</span>
          </div>
        </el-col>
        <el-col :span="8">
          <img src="@/assets/about/3.png" alt="">
          <div>
            <span class="s-title f16 mtb-10">快速交付</span>
            <span class="s-desc f14 c666">职业：项目经理均通过PMP认证。</span>
            <span class="s-desc f14 c666">规范：采取规范化项目管理流程。</span>
            <span class="s-desc f14 c666">效率：使用成熟项目管理软件进行流程管理。</span>
          </div>
        </el-col>
        <el-col :span="8">
          <img src="@/assets/about/4.png" alt="">
          <div>
            <span class="s-title f16 mtb-10">品质保证</span>
            <span class="s-desc f14 c666">积累：已经通过ISO体系认证。</span>
            <span class="s-desc f14 c666">品质：专业的QA团队。</span>
            <span class="s-desc f14 c666">标准：标准化过程，避免人员依赖。</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bg-f7 mtb-40 pb-40" style="overflow:hidden">
      <div class="team-box wrapper text-center">
        <p class="title">企业文化</p>
        <img src="@/assets/about/5.png" alt="">
      </div>
    </div>
    <div class="honorary-qualifications">
      <p class="title">荣誉资质</p>
      <div class="wrapper" style="height: 317px;">
        <el-row :gutter="40">
          <el-col :span="6" v-for="(item, index) in honoraryQualification" :key="index">
            <el-card shadow="always">
              <div class="img-box">
                <img :src="item.imgSrc" alt="">
              </div>
              <p class="s-title">{{ item.name }}</p>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div class="btn-box">
        <img src="@/assets/about/left-icon.png" alt="" @click="leftClick">
        <img src="@/assets/about/right-icon.png" alt="" @click="rightClick">
      </div>
    </div>
    <p class="title">快乐易锐思</p>
    <div class="people wrapper">
      <el-row :gutter="10" class="mb10">
        <!-- <el-col class="people-item" :span="16" :style="{'backgroundImage':`url(${require('@/assets/about/6.png')})`}"> -->
        <el-col class="people-item" :span="16">
          <!-- <span class="tip">团建活动</span> -->
          <img src="@/assets/about/6.png" alt="">
        </el-col>
        <!-- <el-col class="people-item" :span="8" :style="{'backgroundImage':`url(${require('@/assets/about/7.png')})`}"> -->
        <el-col class="people-item" :span="8">
          <!-- <span class="tip">咖啡吧</span> -->
          <img src="@/assets/about/7.jpg" alt="">
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mb10">
        <!-- <el-col class="people-item" :span="16" :style="{'backgroundImage':`url(${require('@/assets/about/8.png')})`}"> -->
        <el-col class="people-item" :span="16">
          <!-- <span class="tip">度假日</span> -->
          <img src="@/assets/about/8.png" alt="">
        </el-col>
        <!-- <el-col class="people-item" :span="8" :style="{'backgroundImage':`url(${require('@/assets/about/9.png')})`}"> -->
        <el-col class="people-item" :span="8">
          <!-- <span class="tip">能量站</span> -->
          <img src="@/assets/about/9.png" alt="">
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <!-- <el-col class="people-item" :span="10" :style="{'backgroundImage':`url(${require('@/assets/about/10.png')})`}"> -->
        <el-col class="people-item" :span="10">
          <!-- <span class="tip">节日福利</span> -->
          <img src="@/assets/about/10.png" alt="">
        </el-col>
        <!-- <el-col class="people-item" :span="14" :style="{'backgroundImage':`url(${require('@/assets/about/11.png')})`}"> -->
        <el-col class="people-item" :span="14">
          <!-- <span class="tip">年终会议</span> -->
          <img src="@/assets/about/11.png" alt="">
        </el-col>
      </el-row>
    </div>
    <div class="work-coding">
      <p class="title">工作环境</p>
      <div class="wrapper">
        <p class="sub-title text-center c666 pb-40">高效开放的办公环境，开放式工位，即时讨论，让灵感碰撞不受空间约束。</p>
        <el-row :gutter="25">
          <el-col :span="8">
            <img src="@/assets/about/12.png" alt="">
          </el-col>
          <el-col :span="16">
            <el-row :gutter="20">
              <el-col :span="24" style="margin-bottom:24px">
                <img src="@/assets/about/13.png" alt="">
              </el-col>
            </el-row>
            <el-row :gutter="25">
              <el-col :span="12">
                <img src="@/assets/about/14.png" alt="">
              </el-col>
              <el-col :span="12">
                <img src="@/assets/about/15.png" alt="">
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="join-us">
      <div class="wrapper">
        <p class="title">加入我们</p>
        <span>请将您的简历投递至邮箱 <a class="email" href="mailto:hr@iriets.com">hr@iriets.com</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      honoraryQualifications: [
        {
          name: "高新技术企业",
          imgSrc: require("@/assets/about/17.png")
        },
        {
          name: "软件企业",
          imgSrc: require("@/assets/about/18.png")
        },
        {
          name: "软件产品",
          imgSrc: require("@/assets/about/19.png")
        },
        {
          name: "ISO 9001",
          imgSrc: require("@/assets/about/20.png")
        },
        {
          name: "ISO 14001",
          imgSrc: require("@/assets/about/21.png")
        },
        {
          name: "ISO 45001",
          imgSrc: require("@/assets/about/22.png")
        },
        {
          name: "ISO 20000",
          imgSrc: require("@/assets/about/23.png")
        },
        {
          name: "ISO 27001",
          imgSrc: require("@/assets/about/24.png")
        },
        {
          name: "软件著作权35项",
          imgSrc: require("@/assets/about/25.jpg")
        }
      ],
      honoraryQualification1: [
        {
          name: "高新技术企业",
          imgSrc: require("@/assets/about/17.png")
        },
        {
          name: "软件企业",
          imgSrc: require("@/assets/about/18.png")
        },
        {
          name: "软件产品",
          imgSrc: require("@/assets/about/19.png")
        },
        {
          name: "ISO 9001",
          imgSrc: require("@/assets/about/20.png")
        }
      ],
      honoraryQualification2: [
        {
          name: "ISO 14001",
          imgSrc: require("@/assets/about/21.png")
        },
        {
          name: "ISO 45001",
          imgSrc: require("@/assets/about/22.png")
        },
        {
          name: "ISO 20000",
          imgSrc: require("@/assets/about/23.png")
        },
        {
          name: "ISO 27001",
          imgSrc: require("@/assets/about/24.png")
        }
      ],
      honoraryQualification3: [
        {
          name: "软件著作权35项",
          imgSrc: require("@/assets/about/25.jpg")
        },
        {
          name: "高新技术企业",
          imgSrc: require("@/assets/about/17.png")
        },
        {
          name: "软件企业",
          imgSrc: require("@/assets/about/18.png")
        },
        {
          name: "软件产品",
          imgSrc: require("@/assets/about/19.png")
        }
      ],
      honoraryQualification: [],
      index: 1,
      clientWidth: document.body.clientWidth
    };
  },
  created() {},
  mounted() {
    if (this.clientWidth < 769) {
      this.honoraryQualification = [this.honoraryQualifications[0]];
    } else {
      this.honoraryQualification = this.honoraryQualification1;
    }

    console.log(this.clientWidth, "document.body.clientWidth ");
  },
  methods: {
    leftClick() {
      if (this.clientWidth < 769) {
        if (this.index == 1) {
          this.honoraryQualification = [
            this.honoraryQualifications[this.honoraryQualifications.length - 1]
          ];
          this.index = this.honoraryQualifications.length;
        } else {
          this.honoraryQualification = [
            this.honoraryQualifications[this.index - 2]
          ];
          this.index--;
        }
      } else {
        if (this.index == 1) {
          this.index = 3;
          this.honoraryQualification = this.honoraryQualification3;
        } else if (this.index == 3) {
          this.index = 2;
          this.honoraryQualification = this.honoraryQualification2;
        } else if (this.index == 2) {
          this.index = 1;
          this.honoraryQualification = this.honoraryQualification1;
        }
      }
      console.log(
        "-------index--------",
        this.index,
        this.honoraryQualification
      );
    },
    rightClick() {
      if (this.clientWidth < 769) {
        if (this.index == this.honoraryQualifications.length) {
          this.honoraryQualification = [this.honoraryQualifications[0]];
          this.index = 1;
        } else {
          this.honoraryQualification = [
            this.honoraryQualifications[this.index]
          ];
          this.index++;
        }
      } else {
        if (this.index == 1) {
          this.index = 2;
          this.honoraryQualification = this.honoraryQualification2;
        } else if (this.index == 2) {
          this.index = 3;
          this.honoraryQualification = this.honoraryQualification3;
        } else if (this.index == 3) {
          this.index = 1;
          this.honoraryQualification = this.honoraryQualification1;
        }
      }
      console.log(
        "-------index--------",
        this.index,
        this.honoraryQualification
      );
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
.team-box {
  .s-title {
    display: block;
    padding-bottom: 5px;
  }
  .s-desc {
    display: block;
    margin-top: 10px;
  }
}
.img-box {
  width: 170px;
  height: 165px;
  line-height: 165px;
  text-align: center;
  margin: 0 auto 30px;
  img {
    display: inline-block;
    vertical-align: middle;
  }
}
.btn-box {
  height: 60px;
  width: 140px;
  display: flex;
  justify-content: space-between;
  margin: 30px auto 0;
  img {
    height: 60px;
    width: 60px;
    cursor: pointer;
  }
}
.people {
  // background: #8578cc;
  .el-col {
    overflow: hidden;
  }
  .el-col img:hover {
    // transform: scale(1.4);
  }
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s;
  }
  .people-item {
    position: relative;
    background-size: cover;
    .tip {
      position: absolute;
      left: 40px;
      top: 20px;
      color: #fff;
      z-index: 1;
    }
  }
}
.work-coding {
  img {
    width: 100%;
  }
}
.join-us {
  height: 248px;
  margin-top: 80px;
  overflow: hidden;
  background: url("../assets/about/16.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  .title {
    margin-bottom: 30px;
    margin-top: 70px;
    color: #fff;
  }
  .email:link {
    color: #fff;
    text-decoration: none;
    text-decoration: underline;
  } /* 未访问的链接 */
  .email:visited {
    color: #fff;
    text-decoration: line-through;
  } /* 已访问的链接 */
  .email:hover {
    color: #fff;
    text-decoration: underline;
  } /* 鼠标移动到链接上 */
  .email:active {
    color: #fff;
  } /* 选定的链接 */
}
</style>
